import React, { useState } from "react";
import "./App.css";
import { TopicContext } from "./components/TopicContext";
import { NavBar } from "./components/NavBar";
import { IntroSlide } from "./components/IntroSlide";
import Media from "./components/mediaContentWindow";
import SplashScreen from "./components/SplashScreen";

const App = () => {
  const [topicType, setTopicType] = useState("");
  const [topicChosen, setTopicChosen] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  return (
    <TopicContext.Provider
      value={{
        topicType,
        setTopicType,
        topicChosen,
        setTopicChosen,
        showTitle,
        setShowTitle,
        showSplash,
        setShowSplash,
      }}
    >
      {topicChosen ? (
        <div className="App">
          <NavBar />
          <Media />
        </div>
      ) : (
        <div className="App">
          <SplashScreen />
          <IntroSlide />
        </div>
      )}
    </TopicContext.Provider>
  );
};
export default App;
// export default App;
