import React from "react";
import "./slides.css";
import Science from "../Science";

export const Conditions = (slides) => {
  const dogOne = slides.data.slides[4].info1;
  const dogTwo = slides.data.slides[4].info2;
  const dogThree = slides.data.slides[4].info3;
  const scienceOne = slides.data.slides[5].info1;
  const scienceTwo = slides.data.slides[5].info2;
  const scienceThree = slides.data.slides[5].info3;
  const footNote = slides.data.slides[5].footNote;

  const handleClick = () => {
    const primarySlide = document.getElementById("primary");
    const secondarySlide = document.getElementById("secondary");
    primarySlide.classList.toggle("hide");
    primarySlide.classList.toggle("is-visible");
    secondarySlide.classList.toggle("hide");
    secondarySlide.classList.toggle("is-visible");
  };
  return (
    <div className="page-container">
      <div className="media-container is-visible" id="primary">
        <div className="hero-image-container">
          <img
            id="hero-image"
            style={{ transform: "scaleX(-1)", left: "10%" }}
            alt="A distinguished and mature bernese mountain dog"
            src="https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/images/04-bernese-mature-transparent.png"
          />
        </div>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {dogOne}
            </li>
            <li className="list-item" id="list-item-two">
              {dogTwo}
            </li>
            <li className="list-item" id="list-item-three">
              {dogThree}
            </li>
          </ul>
          <button className="switch-panel right" onClick={handleClick}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
              alt="next page"
            />
          </button>
        </div>
      </div>
      <div className="media-container" id="secondary">
        <button className="switch-panel left" onClick={handleClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {scienceOne}
            </li>
            <li className="list-item" id="list-item-two">
              {scienceTwo}
            </li>
            <li className="list-item" id="list-item-three">
              {scienceThree}
            </li>
          </ul>
          <p id="footnote">{footNote}</p>
        </div>
        <div className="science-image-container">
          <Science />
        </div>
      </div>
    </div>
  );
};
