import { React, useContext, useState, useEffect } from "react";
import { TopicContext } from "./TopicContext";
import "./media-content.css";
import { Early } from "./slides/Early";
import { Aging } from "./slides/Aging";
import { Conditions } from "./slides/Conditions";
import http from "../lib/http";

function Media() {
  const [slide, setSlide] = useState("");
  // const [splashTitle, setSplashTitle] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/slides/`);
      setSlide(data);
    }
    fetchData();
  });

  const {
    topicType,
    // eslint-disable-next-line
    setTopicType,
    topicChosen,
    // eslint-disable-next-line
    setTopicChosen,
    showTitle,
    setShowTitle,
  } = useContext(TopicContext);

  const titleSlide =
    topicType === "early"
      ? "Early Development"
      : topicType === "aging"
        ? "The Aging Brain"
        : topicType === "conditions"
          ? "Brain Conditions"
          : "Early Development";

  // const setTimer = () => {
  //   setTimeout(() => {
  //     setShowTitle((topic) => false);
  //   }, 3000);}
  const handleClick = () => {
    setShowTitle((topic) => false);
  };
  return !showTitle && topicChosen && topicType === "early" ? (
    <Early {...slide} />
  ) : !showTitle && topicChosen && topicType === "aging" ? (
    <Aging {...slide} />
  ) : !showTitle && topicChosen && topicType === "conditions" ? (
    <Conditions {...slide} />
  ) : topicChosen ? (
    // setTimer(),
    <div className="central-media-container">
      <div className="title-slide">
        <h1>
          {titleSlide}
          <hr />
        </h1>
        <div className="media-start-button" onClick={handleClick}>
          Next
        </div>
      </div>
    </div>
  ) : null;
}
export default Media;
