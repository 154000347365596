import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./intro-slide.css";

export const IntroSlide = () => {
  // eslint-disable-next-line
  const { topicType, setTopicType, topicChosen, setTopicChosen } =
    useContext(TopicContext);
  const handleClick = (e) => {
    setTopicType((topic) => e.currentTarget.id);
    setTopicChosen((topic) => true);
  };

  return (
    <div className="intro-screen">
      <div className="intro-text">
        <h1>
          Only 2% of veterinarians are aware of the link between brain health
          and nutrition.
        </h1>
        <h2>
          Greater knowledge and understanding is an opportunity to have more
          proactive conversations with owners of senior pets.
        </h2>
      </div>
      <div className="topic-section-container">
        <div
          className="topic-option-container"
          id="early"
          onClick={handleClick}
        >
          <img
            className="topic-image"
            alt="puppy portrait"
            src="https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/images/puppy%20thumb.png"
          />
          <button className="topic-button">
            <h3>Early Development</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="aging"
        >
          <img
            className="topic-image"
            alt="Adult dog portrait"
            src="https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/images/pixie%20thumb.png"
          />
          <button className="topic-button">
            <h3>The Aging Brain</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          id="conditions"
          onClick={handleClick}
        >
          <img
            className="topic-image"
            alt="mature dog portrait"
            src="https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/images/brut%20thumb.png"
          />
          <button className="topic-button">
            <h3>Brain Conditions</h3>
          </button>
        </div>
      </div>
    </div>
  );
};
