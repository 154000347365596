import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./nav-bar.css";

export const NavBar = (props) => {
  // eslint-disable-next-line
  const { topicType, setTopicType, showTitle, setShowTitle } =
    useContext(TopicContext);
  const navBarTopics = Array.from(
    document.getElementsByClassName("navigation-link"),
  );
  const handleClick = (e) => {
    setTopicType((topic) => e.target.id);
    setShowTitle((topic) => true);
    navBarTopics.forEach((el) => el.classList.remove("selected"));
    e.target.classList.add("selected");
  };
  const initializeTopic = navBarTopics.forEach((el) => {
    el.id === topicType
      ? el.classList.add("selected")
      : el.classList.remove("selected");
  });
  const dropdownClick = (e) => {
    e.target.classList.toggle("open");
  };
  return (
    initializeTopic,
    (
      <header className="app-header">
        <div className="nav-link-container">
          <div onClick={handleClick} id="early" className="navigation-link">
            Early Development
            <div className="lozenge"></div>
          </div>
          <div onClick={handleClick} id="aging" className="navigation-link">
            The Aging Brain
            <div className="lozenge"></div>
          </div>
          <div
            onClick={handleClick}
            id="conditions"
            className="navigation-link"
          >
            Brain Conditions
            <div className="lozenge"></div>
          </div>
          <div className="navigation-link empty"></div>
          <div className="navigation-link empty"></div>
          <div className="dropdown" onClick={dropdownClick}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Selected%20Info%20Icon.png"
              alt="Learn More"
            />
            <div className="dropdown-content">
              <div>
                <a
                  id="learn-more"
                  href="https://www.purinainstitute.com/advancing-brain-health"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Learn More</p>
                </a>
              </div>

              <div id="references">
                <a
                  href="https://www.purinainstitute.com/advancing-brain-health/references-list"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>References</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </header>
    )
  );
};
